<template>
  <div>
    <v-row class="pa-4">
      <v-col cols="6">
        <h1 class="d-flex font-weight-light display-1">Explore</h1>
      </v-col>
      <v-col cols="6" class="text-right">
        <v-icon>mdi-truck-outline</v-icon> Online {{ trucksOnline.length }} /
        Offline {{ trucksOffline.length }} / Total {{ trucksArray.length }}
      </v-col>
    </v-row>
    <v-container fluid grid-list-lg>
      <h3 class="mb-5" v-if="isFiltering">
        Matches for filter ({{ filteredTrucks.length }})
        <v-chip-group active-class="white--text text--accent-4">
          <v-chip
            v-for="(value, key) in validFilters"
            :key="key"
            small
            dark
            :class="value | filterColor(key)"
            close
            @click:close="addRemoveFromFilter(key, value)"
          >
            {{ value | filterText(key) }}
          </v-chip>
        </v-chip-group>
      </h3>
      <ExploreFleetCard
        v-for="(truck, key) in filteredTrucks"
        :key="key"
        :truck="truck"
        @chipClick="addRemoveFromFilter"
      />
    </v-container>
    <v-snackbar
      app
      v-for="(s, i) in snackbars"
      :key="i"
      v-model="s.show"
      :timeout="timeout"
      :right="true"
      :top="true"
      :min-width="300"
      :color="`${s.color} darken-4`"
      :style="{ 'margin-bottom': calcMargin(i) }"
    >
      <v-icon large>{{ s.icon }}</v-icon>
      <span class="font-weight-medium ml-3">{{ s.message }}</span>
    </v-snackbar>
  </div>
</template>

<script>
import auth from "@/services/auth.service";
export default {
  name: "Explore",
  data: () => ({
    snackbars: [],
    timeout: 4000,
    trucks: {},
    filters: {
      environment: null,
      is_moving: null,
      bluetooth_works: null,
      gps_works: null,
      wifi_works: null,
      in_office: null,
      isFarFromHome: null,
      campaign: null,
    },
  }),
  components: {
    ExploreFleetCard: () => import("@/components/ExploreFleetCard"),
  },
  created() {
    this.trucks = this.$root.truckList.reduce(
      (obj, item) => ((obj[item.id] = item), obj),
      {}
    );
  },
  filters: {
    filterText(value, key) {
      switch (key) {
        case "environment":
          return [
            "Standby",
            "Pre-production",
            "Production",
            "Development",
            "In-transit",
            "Decomissioned",
            "Unlisted",
          ][value];
        case "is_moving":
          if (value) return "Moving";
          break;
        case "bluetooth_works":
          if (value === false) return "bluetooth failure";
          break;
        case "gps_works":
          if (value === false) return "GPS failure";
          break;
        case "in_office":
          if (value === true) return "In Office";
          break;
        case "wifi_works":
          if (value === false) return "WIFI failure";
          break;
        case "isFarFromHome":
          if (value) return "far from home";
          break;
      }
      return value;
    },
    filterColor(value, key) {
      if (key === "environment") {
        const environments = [
          "lime",
          "light-green",
          "green",
          "lightgreen",
          "teal",
          "cyan",
        ];
        return environments[value];
      } else if (key === "campaign") return "primary";
      else if (key === "isFarFromHome") return "red";
      else if (key === "in_office") return "pink";

      return value ? "teal" : "red";
    },
  },
  methods: {
    calcMargin(i) {
      return i * 60 + "px";
    },
    hide(i) {
      this.snackbars.splice(i, 1);
    },
    initSocket() {
      const wsURI = `wss://c2.movia.media/ws/events`;
      this.connection = new WebSocket(wsURI);
      // reconnect on errors / close
      this.connection.onerror = () => {
        console.log("Socket error, reconnecting");
      };

      this.connection.onopen = () => {
        this.connection.send(auth.getAuthTokenFromStorage());
      };

      this.connection.onclose = () => {
        console.log("Socket closed, reconnecting");
      };

      this.connection.onmessage = (event) => {
        const data = JSON.parse(event.data);
        switch (data.tag) {
          case "websocket.report":
            this.trucks[data.id] = {
              ...this.trucks[data.id],
              ...data,
              latitude:
                data.lat ||
                (this.trucks[data.id] && this.trucks[data.id].latitude),
              longitude:
                data.lon ||
                (this.trucks[data.id] && this.trucks[data.id].longitude),
              online: true,
              bluetooth_works: data.beacons != 0,
              gps_works: data.lat != 0 || !data.apple,
              is_moving: data.speed >= 5,
              wifi_works:
                data.probes > 0 || data.clients > 0 || data.stations > 0,
              isFarFromHome: data.dfh > 500,
            };
            break;
          case "teleport.event":
            this.snackbars.push({
              show: true,
              color: "purple",
              icon: "mdi-alert",
              message: `${data.device_id} teleported ${data.distance.toFixed(
                2
              )} km`,
            });
            break;
          case "device.online":
            if (data.device_id) {
              this.trucks[data.device_id].online = true;
              this.snackbars.push({
                show: true,
                color: "green",
                icon: "mdi-check",
                message: `${data.device_id} is now online`,
              });
            }
            break;
          case "device.offline":
            if (data.device_id) {
              this.trucks[data.device_id].online = false;
              this.snackbars.push({
                show: true,
                color: "pink",
                icon: "mdi-alert",
                message: `${data.device_id} is now offline`,
              });
            }
            break;
        }
      };
    },
    addRemoveFromFilter(...args) {
      const [key, value] = args;
      if (this.filters[key] === null) this.filters[key] = value;
      else this.filters[key] = null;
    },
  },
  mounted() {
    this.initSocket();
  },
  beforeDestroy() {
    this.connection.close();
  },
  computed: {
    trucksArray() {
      return Object.values(this.trucks);
    },
    trucksOffline() {
      return this.trucksArray.filter((truck) => !truck.online);
    },
    trucksOnline() {
      return this.trucksArray.filter((truck) => truck.online);
    },
    filteredTrucks() {
      return this.trucksOnline
        .filter((truck) => {
          return Object.keys(this.validFilters).every(
            (key) => truck[key] === this.validFilters[key]
          );
        })
        .sort((a, b) => {
          if (a.id > b.id) return 1;
          else if (a.id < b.id) return -1;
          else return 0;
        });
    },
    isFiltering() {
      return Object.keys(this.validFilters).length > 0;
    },
    validFilters() {
      let tempFilter = {};
      Object.keys(this.filters).forEach((key) => {
        if (this.filters[key] !== null) tempFilter[key] = this.filters[key];
      });

      return tempFilter;
    },
  },
};
</script>
