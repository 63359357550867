import api from '@/api'

const REFRESH_TIME_IN_MINUTES = 3

function refreshToken() {
  return api.refreshToken(getAuthTokenFromStorage()).then((auth) => {
    setAuthTokenToStorageFromAuthProcess(auth)
  })
}

function getAuthTokenFromStorage(){
  return localStorage.getItem('token')
}

/*
  @param {obj} auth - Auth Object returned from auth process
                     either of login, register or refresh token
*/
// @todo look into replacing the auth parameter with a async function 
function setAuthTokenToStorageFromAuthProcess(auth) {
  if (auth.token) {
    localStorage.setItem('token', auth.token);
  }
}

function removeAuthTokenFromStorage(){
  // @TODO: turn this to be promise
  localStorage.removeItem('token');
}

export default {
  register: async function ({ email, password, confirmPassword }) {
    try {
      const auth = await api.register(email, email, password, confirmPassword)
      setAuthTokenToStorageFromAuthProcess(auth)
      // if we replace this with vue router, we will need to do a set timeout for refresh token here
      window.location.replace('/');
    } catch (e) {
      return Promise.reject(e);
    }
  },
  login: async function ({ email, password }) {
    try {
      const auth = await api.login(email, password)
      setAuthTokenToStorageFromAuthProcess(auth)
      // if we replace this with vue router, we will need to do a set timeout for refresh token here
      window.location.replace('/');
    } catch (e) {
      return Promise.reject(e);
    }
  },
  logout: function () {
    removeAuthTokenFromStorage()
    // if we replace this with vue router, we will need to do a clear timeout
    window.location.replace('/auth');
  },
  initializeTokenRefresh: async function () {
    if (getAuthTokenFromStorage()) {
      try {
        await refreshToken()
        const intervalId = setInterval(
          () => refreshToken().catch(() => clearInterval(intervalId)),
          REFRESH_TIME_IN_MINUTES * 60000
        )
      } catch (e) {
        this.logout()
      }

    }
  },
  getAuthTokenFromStorage,
  removeAuthTokenFromStorage
}