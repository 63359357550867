import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import VueApexCharts from 'vue-apexcharts'
import api from '@/api'
import { LMap, LTileLayer, LMarker, LPopup, LPolyline } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

Vue.config.productionTip = false
Vue.component("apexchart", VueApexCharts);
Vue.use(VueApexCharts)
Vue.use(require('vue-moment'));
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-popup', LPopup);
Vue.component('l-polyline', LPolyline);

Vue.prototype.$api = api
Vue.filter('countryCode', function (value) { 
  if (value === 'Canada')
    return 'CA'
  else if(value === "United States")
    return "US"

  return value 
})

Vue.filter('toFixed', function (number, digits = 0) { 
  return number > 0 ? number.toFixed(digits) : "0"; 
})

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
