<template>
  <v-text-field
    type="password"
    label="Confirm Password"
    :value="value"
    :rules="rules"
    @input="$emit('input', $event)"
    :error-messages="error"
    required
  ></v-text-field>
</template>

<script>
export default {
  name: "Signup",
  props: {
    rules: Array,
    value: String,
    isPasswordEqual: Boolean,
  },
  computed: {
    error() {
      // Only validate when confirm password field has a value and
      // when isPasswordEqual is strictly false (undefined and null are not considered)
      if (this.value && this.isPasswordEqual === false)
        return "Password does not match";
      else return [];
    },
  },
};
</script>
