import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import Login from '../views/Login.vue'
import Signup from '../views/Signup.vue'
import Analytics from '../views/Analytics.vue'
import FsaMap from '../views/FsaMap.vue'
import Enroll from '../views/Enroll.vue'
import Explore from '../views/Explore.vue'
import ExploreFleet from '../views/ExploreFleet.vue'
import NotFound from '../views/404.vue'
import Insights from '../views/Insights.vue'
import Profile from '../views/Profile.vue'
import Activity from '../views/Activity.vue'
import MACSSID from '../views/MACSSID.vue'
import auth from "@/services/auth.service";

Vue.use(VueRouter)

const routes = [
  {
    path: '/auth',
    name: 'Login',
    component: Login,
    children: [
      {
        path: 'register',
        name: 'Signup',
        component: Signup
      }
    ]
  },
  {
    path: '/overview',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/analytics/:qstring',
    name: 'Analytics',
    component: Analytics
  },
  {
    path: '/fsa/:qstring',
    name: 'FsaMap',
    component: FsaMap
  },
  {
    path: '/explore',
    name: 'Explore',
    component: Explore
  },
  {
    path: '/explore/:fleetId',
    name: 'ExploreFleet',
    component: ExploreFleet
  },
  {
    path: '/ssid/:ssid',
    name: 'SSID',
    component: MACSSID
  },
  {
    path: '/mac/:mac',
    name: 'MACAddr',
    component: MACSSID
  },
  {
    path: '/insights',
    name: 'Insights',
    component: Insights
  },
  {
    path: '/activity',
    name: 'Activity',
    component: Activity
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/enroll',
    name: 'Enroll',
    component: Enroll
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // redirect request to root to dashboard
  if(to.path === '/'){
    next({ name: 'Dashboard' })
  } else {
    const isAuthPage = to.fullPath.includes('/auth')
    const isLoggedIn = auth.getAuthTokenFromStorage();

    if (isAuthPage){
      if(isLoggedIn)
        next({ name: 'Dashboard' })
      else if(to.name !== 'Login' && to.name !== 'Signup')
        next({ name: 'Login' })
      else
        next()
    } else {
      if (isLoggedIn)
        next()
      else
        next({ name: 'Login' }) 
    }
  }
})

export default router
