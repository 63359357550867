<template>
  <v-app v-if="isOnAuthRoute || loaded">
    <TheHeader v-if="!isOnAuthRoute" />
    <v-main>
      <v-container fluid class="h-100">
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import auth from "@/services/auth.service";
export default {
  name: "Home",
  components: {
    TheHeader: () => import("@/components/TheHeader"),
  },
  data: () => ({
    loaded: false,
    isOnAuthRoute: true,
  }),
  watch: {
    $route: {
      handler(to) {
        this.isOnAuthRoute = to.fullPath.includes("/auth");
      },
      immediate: true,
    },
  },
  async mounted() {
    this.$root.mapTileAttribution = this.$mapTileAttribution;
    if (!this.isOnAuthRoute) {
      await auth.initializeTokenRefresh();
      this.$api.getProfile().then((profile) => {
        this.$root.profile = profile;
      });
      this.$api.getFleets().then((fleets) => {
        this.$root.truckList = fleets;
        this.loaded = true;
      });
    }
  },
};
</script>
<style>
.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.h-25 {
  height: 25%;
}

.vh-100 {
  height: 100vh;
}
</style>

