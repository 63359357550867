<template>
  <v-container fluid>
    <div
      class="d-flex justify-center align-center vh-100"
      v-if="loading || !profile"
    >
      <v-progress-circular
        v-if="loading"
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <div v-else class="d-flex flex-column h-25 justify-space-between">
        <v-icon x-large color="red" style="font-size: 160px">
          mdi-alert-octagon-outline
        </v-icon>
        <span class="red--text text--darken-4 font-weight-black text-center pb-3"
          >Something bad happened :(</span
        >
        <v-btn to="/" dark elevation="0" color="red--text"
          >Go back to fleet overview</v-btn
        >
      </div>
    </div>
    <template v-else>
      <v-row>
        <v-col cols="12" lg="6">
          {{ profile.first_name }}
          {{ profile.last_name }}
          {{ profile.email }}
          {{ profile.last_login }}
        </v-col>
      </v-row>
    </template>
    <v-snackbar
      v-model="snackbar"
      :right="true"
      :top="true"
      :min-width="300"
      color="red darken-4"
    >
      <v-icon large>mdi-close-octagon-outline</v-icon>
      <span class="font-weight-medium ml-3">Couldn't load data</span>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "Profile",
  data() {
    return {
      loading: false,
      snackbar: false,
      profile: this.$root.profile,
    };
  },
};
</script>

<style>
#map {
  height: 100%;
  width: 100%;
}
.map-frame {
  width: 100%;
  height: 100%;
  position: relative;
}

.map-content {
  z-index: 10;
  position: absolute;
  top: 50px;
  left: 50px;
  width: 390px;
  background-color: black;
  color: #fff;
}
</style>
