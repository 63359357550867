<template>
  <div>
    <v-sheet class="pa-md-4 mx-lg-auto"
             flat>
      <v-row>
        <v-col cols="12"
               sm="5">
          <v-btn depressed
                 small
                 dark
                 color="green"
                 @click="identify(device)"
                 class="hidden-sm-and-down">
            <v-icon left
                    dark>mdi-alarm-light-outline</v-icon> Identify
          </v-btn>
          &nbsp;
          <v-btn depressed
                 small
                 dark
                 color="orange"
                 @click="upgrade(device)"
                 class="hidden-sm-and-down">
            <v-icon left
                    dark>mdi-update</v-icon> Update OS
          </v-btn>
          &nbsp;
          <v-btn depressed
                 small
                 dark
                 color="red"
                 @click="reboot(device)"
                 class="hidden-sm-and-down">
            <v-icon left
                    dark>mdi-restart</v-icon> Reboot
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12">
          <strong>
            {{ strings.display_name }}
          </strong>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12"
               md="8">
          <ExploreFleetMap :events="events"
                           :location="location" />
        </v-col>

        <v-col sm="12"
               md="4">
          <v-row no-gutters>
            <v-col>
              <strong>Device {{ strings.device_id }} (Truck
                {{ counters.truck_id }})</strong>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Environment</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ env2str(strings.env) }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Software version</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ strings.version || counters.version }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Base version</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ strings.base_version }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>System uptime</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ counters.uptime }} seconds</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>CPU utilization</v-col>
            <v-spacer></v-spacer>
            <v-col> {{ counters.cpu.toFixed(2) }}% </v-col>
            <v-progress-linear :value="counters.cpu.toFixed(2)"></v-progress-linear>
          </v-row>
          <v-row no-gutters>
            <v-col>Memory utilization</v-col>
            <v-spacer></v-spacer>
            <v-col> {{ counters.memory.toFixed(2) }}% </v-col>
            <v-progress-linear :value="counters.memory.toFixed(2)"></v-progress-linear>
          </v-row>

          <v-row no-gutters>
            <v-col>Data received</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ counters.bytes_rx }} bytes</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Data sent</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ counters.bytes_tx }} bytes</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Temperature</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ (counters.temp / 1000).toFixed(2) }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Bluetooth</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ counters.beacons }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Clients</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ counters.clients }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Phones</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ counters.probes }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>AP's</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ counters.stations }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Scans since boot</v-col>
            <v-spacer></v-spacer>
            <v-col>{{
              counters.stations +
              counters.clients +
              counters.probes +
              counters.beacons
            }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Throttled</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ counters.throttled }}</v-col>
          </v-row>
          <v-divider></v-divider>
        </v-col>

        <v-col md="6">
          <v-row no-gutters>
            <v-col>
              <strong>Modem Info</strong>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Carrier</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ strings.carrier }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Access point name</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ strings.apn }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>IMSI</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ strings.imsi }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>IMEI</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ strings.imei }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>ICCID</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ strings.iccid }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Signal quality</v-col>
            <v-spacer></v-spacer>
            <v-col> {{ strings.rssi }}% </v-col>
            <v-progress-linear :value="strings.rssi"></v-progress-linear>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Operator Code</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ strings.op_code }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Operator Name</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ strings.op_name }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Cell ID</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ strings.cell_id }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Mobile country code</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ strings.mcc }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Mobile network code</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ strings.mnc }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Location area code</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ strings.lac }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Tracking area code</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ strings.tac }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>RTT</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ strings.rtt }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Interface</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ strings.interface }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>IPv4 address</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ strings.ipv4 }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Gateway address</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ strings.gateway }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Roaming</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ strings.roaming }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Connected</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ strings.connected }}</v-col>
          </v-row>
          <v-divider></v-divider>
        </v-col>

        <v-col md="6">
          <v-row no-gutters>
            <strong>GPS Info</strong>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Mode</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ counters.mode }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Latitude</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ counters.lat }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Longitude</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ counters.lon }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Speed</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ counters.speed.toFixed(2) }} km/h</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Timestamp error</v-col>
            <v-spacer></v-spacer>
            <v-col>+/- {{ counters.ept }} seconds</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Longitude error</v-col>
            <v-spacer></v-spacer>
            <v-col>+/- {{ counters.epx }} metres</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Latitude error</v-col>
            <v-spacer></v-spacer>
            <v-col>+/- {{ counters.epy }} metres</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Climb error</v-col>
            <v-spacer></v-spacer>
            <v-col>+/- {{ counters.epc }} metres/s</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Speed error</v-col>
            <v-spacer></v-spacer>
            <v-col>+/- {{ counters.eps }} metres/s</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Horizontal position error</v-col>
            <v-spacer></v-spacer>
            <v-col>+/- {{ counters.eph }} metres</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Vertical position error</v-col>
            <v-spacer></v-spacer>
            <v-col>+/- {{ counters.epv }} metres</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Spherical position error</v-col>
            <v-spacer></v-spacer>
            <v-col>+/- {{ counters.sep }} metres</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>GeoID separation</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ counters.geoidSep }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Climb</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ counters.climb }} metres/s</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Altitude</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ counters.alt }} metres</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Altitude, height above ellipsoid</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ counters.altHAE }} metres</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>MSL altitude</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ counters.altMSL }} metres</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Leap seconds</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ counters.leapseconds }}</v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters>
            <v-col>Magnetic variation</v-col>
            <v-spacer></v-spacer>
            <v-col>{{ getCardinalDirection }} {{ counters.magvar }}°</v-col>
          </v-row>
          <v-divider></v-divider>
        </v-col>
      </v-row>

      <v-row class="fill-height">
        <v-col>
          <v-sheet height="64">
            <v-toolbar flat>
              <v-btn outlined
                     class="mr-4"
                     color="grey darken-2"
                     @click="setToday">
                Today
              </v-btn>
              <v-btn fab
                     text
                     small
                     color="grey darken-2"
                     @click="prev">
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn fab
                     text
                     small
                     color="grey darken-2"
                     @click="next">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-menu bottom
                      right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined
                         color="grey darken-2"
                         v-bind="attrs"
                         v-on="on">
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>Day</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>Week</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Month</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = '4day'">
                    <v-list-item-title>4 days</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-spacer></v-spacer>
              <v-slider v-model="minSpeed"
                        label="Minimum Speed"
                        thumb-color="blue"
                        thumb-label="always"
                        @change="updateSessions"></v-slider>
              <v-progress-circular :size="25"
                                   color="primary"
                                   indeterminate
                                   v-if="!doneLoading"></v-progress-circular>
            </v-toolbar>
          </v-sheet>

          <v-sheet height="800">
            <v-calendar ref="calendar"
                        v-model="focus"
                        color="primary"
                        :events="calendarEvents"
                        :event-color="getEventColor"
                        :type="type"
                        @click:event="showEvent"
                        @click:more="viewDay"
                        @click:date="viewDay"
                        @change="updateRange"></v-calendar>
            <v-menu v-model="selectedOpen"
                    :close-on-content-click="false"
                    :activator="selectedElement"
                    offset-x>
              <v-card color="grey lighten-4"
                      flat>
                <v-toolbar :color="selectedEvent.color"
                           dark>
                  <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <div class="innerMap">
                    <l-map id="innerMap"
                           :zoom="4"
                           :center="[42, -84]"
                           ref="innerMap">
                      <l-tile-layer style="height: 320px; width: 100%"
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    :attribution="$root.mapTileAttribution">
                      </l-tile-layer>
                      <l-marker :lat-lng="startLocation"
                                :icon="truckIcon">
                      </l-marker>
                      <l-marker :lat-lng="[ev.lat, ev.lon]"
                                :icon="getIcon()"
                                v-for="(ev, idx) in filteredSessionEvents"
                                :key="idx">
                      </l-marker>
                    </l-map>
                  </div>
                  <strong>Start address :</strong>
                  <span v-html="selectedEvent.start_address"></span>
                  <strong>at</strong> {{ selectedEvent.start }}
                  <br />
                  <strong>End address :</strong>
                  <span v-html="selectedEvent.end_address"></span>
                  <strong>at</strong> {{ selectedEvent.end }}
                </v-card-text>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h1 class="display-1">Scans</h1>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col>
          <v-text-field v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details></v-text-field>
          <v-data-table dense
                        :headers="headers"
                        :items="events"
                        :items-per-page="25"
                        :search="search"
                        height="900px">
            <template v-slot:item.type="{ item }">
              <v-chip :color="getTypeColor(item.type)"
                      dark
                      small>
                <v-icon>{{ getTypeIcon(item.type) }}</v-icon>
              </v-chip>
            </template>

            <template v-slot:item.encryption="{ item }">
              <v-chip :color="getEncColor(item.encryption)"
                      dark
                      small>
                {{ getEncText(item.encryption) }}
              </v-chip>
            </template>

            <template v-slot:item.mac_addr="{ item }">
              <router-link :to="{ path: `/mac/${item.mac_addr}` }">
                {{ item.mac_addr }}
                <br />
                {{ item.vendor }}
              </router-link>
            </template>

            <template v-slot:item.ap_mac_addr="{ item }">
              <router-link :to="{ path: `/mac/${item.ap_mac_addr}` }">
                {{ item.ap_mac_addr }}
                <br />
                {{ item.ap_vendor }}
              </router-link>
            </template>

            <template v-slot:item.ssid="{ item }">
              <span v-if="item.ssid">
                <router-link :to="{ path: `/ssid/${item.ssid}` }">
                  {{ item.ssid }}
                </router-link>
              </span>
            </template>

            <template v-slot:item.rssi="{ item }">
              {{ rssi2percent(item.rssi) }}%
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-snackbar app
                  v-for="(s, i) in snackbars"
                  :key="i"
                  v-model="s.show"
                  :timeout="timeout"
                  :right="true"
                  :top="true"
                  :min-width="300"
                  :color="`${s.color} darken-4`"
                  :style="{ 'margin-bottom': calcMargin(i) }">
        <v-icon large>{{ s.icon }}</v-icon>
        <span class="font-weight-medium ml-3">{{ s.message }}</span>
      </v-snackbar>
    </v-sheet>
  </div>
</template>
<script>
import auth from "@/services/auth.service";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import handleError from "@/views/ErrorHandler";

export default {
  name: "ExploreDevice",
  components: {
    ExploreFleetMap: () => import("@/components/ExploreFleetMap"),
  },
  data: () => ({
    calendarStart: "",
    calendarEnd: "",
    centerMap: { lat: 42.11669429932734, lng: -101.2996540180062 },
    doneLoading: false,
    sessionEvents: [],
    filteredSessionEvents: [],
    startLocation: [0, 0],
    snackbars: [],
    timeout: 4000,
    search: "",
    minSpeed: 0,
    eventCount: 0,
    truckIcon: L.icon({
      iconUrl: require("@/assets/images/truck.png"),
    }),
    counters: {
      cpu: 0,
      memory: 0,
      temp: 0,
      speed: 0,
      time: "waiting for data...",
      throttled: "waiting for data...",
    },
    countersDelta: {},
    beforeCounters: {},
    strings: {
      time: "waiting for data...",
      carrier: "waiting for data...",
      imsi: "waiting for data...",
      imei: "waiting for data...",
      iccid: "waiting for data...",
      rssi: "waiting for data...",
      ipv4: "waiting for data...",
      op_code: "waiting for data...",
      op_name: "waiting for data...",
      cell_id: "waiting for data...",
      mcc: "waiting for data...",
      mnc: "waiting for data...",
      lac: "waiting for data...",
      tac: "waiting for data...",
      rtt: "waiting for data...",
      gateway: "waiting for data...",
      apn: "waiting for data...",
      connected: "waiting for data...",
      roaming: "waiting for data...",
      interface: "waiting for data...",
      magtrack: "waiting for data...",
    },
    magtrack: 0.0,
    latitude: 0.0,
    longitude: 0.0,
    events: [],
    headers: [
      { text: "Type", value: "type" },
      { text: "MAC", value: "mac_addr" },
      { text: "AP", value: "ap_mac_addr" },
      { text: "SSID", value: "ssid" },
      { text: "Signal Quality", value: "rssi" },
      { text: "Channel", value: "channel" },
      { text: "Frequency", value: "freq" },
      { text: "Encryption", value: "encryption" },
    ],
    location: {
      lat: 0.0,
      lng: 0.0,
    },
    device: false,
    // calendar
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
      "4day": "4 Days",
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    calendarEvents: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    names: [
      "Meeting",
      "Holiday",
      "PTO",
      "Travel",
      "Event",
      "Birthday",
      "Conference",
      "Party",
    ],
    errorDetails: {
      message: null,
      errData: null,
      errorHeader: null,
      url: null
    },
  }),
  methods: {
    getIcon() {
      return L.divIcon({
        className: "my-custom-pin",
        html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 2 2"><circle fill="red" stroke="#000" stroke-width=".1" r=".87"/></svg>`,
      });
    },
    async upgrade(device) {
      try {
        await this.$api.sendUpgradeCommand(device);
      } catch (e) {
        this.errorDetails = handleError(e);
      }
      this.snackbar = {
        show: true,
        color: "success",
        icon: "mdi-check",
        message: `Sent upgrade command to ${device}`,
      };
    },
    async reboot(device) {
      try {
        await this.$api.sendRebootCommand(device);
      } catch (e) {
        this.errorDetails = handleError(e);
      }
      this.snackbar = {
        show: true,
        color: "success",
        icon: "mdi-check",
        message: `Sent reboot command to ${device}`,
      };
    },
    async identify(device) {
      try {
        await this.$api.sendIdentifyCommand(device);
      } catch (e) {
        this.errorDetails = handleError(e);
      }
      this.snackbar = {
        show: true,
        color: "success",
        icon: "mdi-check",
        message: `Sent identify command to ${device}`,
      };
    },
    rssi2percent(dBm) {
      if (dBm <= -100) {
        return 0;
      } else if (dBm >= -50) {
        return 100;
      }
      return 2 * (dBm + 100);
    },
    env2str(env) {
      if (env == 0) {
        return "Standby";
      }
      if (env == 1) {
        return "Pre-production";
      }
      if (env == 2) {
        return "Production";
      }
      if (env == 3) {
        return "Development";
      }
      if (env == 4) {
        return "In-transit";
      }
      if (env == 5) {
        return "Decomissioned";
      }
      if (env == 6) {
        return "Unlisted";
      }
      return "Unknown";
    },
    getEncColor(enc) {
      if (enc == "WPA2") {
        return "red";
      }
      if (enc == "OPEN") {
        return "green";
      }
      return "blue";
    },
    getEncText(enc) {
      if (enc == "WPA2") {
        return "WPA2";
      }
      if (enc == "OPEN") {
        return "OPEN";
      }
      return "N/A";
    },
    getTypeIcon(stype) {
      if (stype == "client") {
        return "mdi-router-wireless";
      }
      if (stype == "station") {
        return "mdi-access-point";
      }
      if (stype == "probe") {
        return "mdi-cellphone-wireless";
      }
      if (stype == "ble") {
        return "mdi-bluetooth";
      }
    },
    getTypeColor(stype) {
      if (stype == "client") {
        return "yellow";
      }
      if (stype == "station") {
        return "orange";
      }
      if (stype == "probe") {
        return "green";
      }
      if (stype == "ble") {
        return "blue";
      }
    },
    initDeviceSocket() {
      const wsURI = `wss://c2.movia.media/ws/${this.device}`;
      this.connection = new WebSocket(wsURI);
      // reconnect on errors / close
      this.connection.onerror = () => {
        console.log("Socket error, reconnecting");
      };

      this.connection.onclose = () => {
        console.log("Socket closed, reconnecting");
      };

      this.connection.onopen = () => {
        this.connection.send(auth.getAuthTokenFromStorage());
      };

      this.connection.onmessage = (event) => {
        this.eventCount += 1;

        var evt = JSON.parse(event.data);
        for (const [key, value] of Object.entries(evt)) {
          if (typeof value == "number" && !isNaN(value) && value != 0) {
            // copy current counter to before time counter
            this.beforeCounters[key] = this.counters[key];
            // deduct the current value from the previous value of this counter to figpure out how much it changed
            this.countersDelta[key] = value - this.counters[key];
            // set the current counter to the one we just got
            this.counters[key] = value;
          } else {
            this.strings[key] = value;
          }
        }
        if (evt.location) {
          this.location = { lat: evt.location.lat, lng: evt.location.lon };
          this.magtrack = evt.magtrack;
        }
        if (evt.type) {
          this.events.unshift(evt);
          if (this.events.length == 1001) {
            this.events.pop();
          }
        }
      };
    },

    // calendar

    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      this.filteredSessionEvents = [];

      this.$api
        .getLocations(
          this.$route.params.fleetId,
          event.start_iso,
          event.end_iso,
          this.minSpeed
        )
        .then((ev) => {
          if (ev.points.length > 1) {
            this.sessionEvents = ev.points;
            var events = this.sessionEvents.filter(
              (event) => event.lon && event.lat
            );
            console.log(ev.distance);
            this.filteredSessionEvents = events;
            this.startLocation = events[0];

            setTimeout(() => {
              //mapObject is a property that is part of leaflet
              this.$refs.innerMap.mapObject.invalidateSize();
              this.$refs.innerMap.mapObject.fitBounds(
                new L.LatLngBounds(events)
              );
            }, 100);
          }
        });

      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      this.calendarStart = start.date;
      this.calendarEnd = end.date;
      this.updateSessions();
    },
    updateSessions() {
      this.doneLoading = false;
      this.$api
        .getSessions(
          this.$route.params.fleetId,
          this.calendarStart,
          this.calendarEnd,
          this.minSpeed
        )
        .then((ev) => {
          this.doneLoading = true;
          this.calendarEvents = ev;
        });
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
  mounted() {
    this.device = this.$route.params.fleetId;
    this.initDeviceSocket();
  },
  beforeDestroy() {
    this.connection.close();
  },

  computed: {
    getCardinalDirection() {
      let angle = this.magtrack;
      if (typeof angle === "string") angle = parseInt(angle);
      if (angle <= 0 || angle > 360 || typeof angle === "undefined") return "?";
      const arrows = {
        north: "↑ N",
        north_east: "↗ NE",
        east: "→ E",
        south_east: "↘ SE",
        south: "↓ S",
        south_west: "↙ SW",
        west: "← W",
        north_west: "↖ NW",
      };
      const directions = Object.keys(arrows);
      const degree = 360 / directions.length;
      angle = angle + degree / 2;
      for (let i = 0; i < directions.length; i++) {
        if (angle >= i * degree && angle < (i + 1) * degree) {
          return arrows[directions[i]];
        }
      }
      return arrows["north"];
    },
  },
};
</script>

<style lang="scss">
.vue2leaflet-map {
  z-index: 1;
}

.innerMap {
  width: 100%;
  height: 400px;
}
</style>
