<template>
  <v-container fluid fill-height>
    <v-row justify="center">
      <v-col cols="12" sm="10" md="6" lg="4">
        <v-form
          v-model="valid"
          @submit.prevent="authenticateOrRegister"
          :readonly="processingForm"
        >
          <v-card class="elevation-12">
            <v-toolbar dark color="#1d2e43">
              <v-toolbar-title>{{
                isOnRegisterRoute ? "Register" : "Login"
              }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-alert border="top" color="red lighten-2" dark v-if="message">
                {{ message }}
              </v-alert>
              <v-text-field
                name="Email"
                label="Email"
                type="email"
                v-model="authForm.email"
                :rules="requiredRule"
              ></v-text-field>
              <v-text-field
                id="password"
                name="Password"
                label="Password"
                type="password"
                v-model="authForm.password"
                :rules="requiredRule"
              ></v-text-field>
              <router-view
                v-model="authForm.confirmPassword"
                :rules="requiredRule"
                :isPasswordEqual="isPasswordEqual"
              ></router-view>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                type="submit"
                color="#1d2e43"
                :dark="valid"
                :loading="processingForm"
                :disabled="!valid"
                >Submit</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
        <p class="text-center mt-4">
          {{
            isOnRegisterRoute
              ? "Already have an account?"
              : "Don't have an account yet?"
          }}
          <router-link
            class="primary--text"
            :to="`${isOnRegisterRoute ? '/auth' : '/auth/register'}`"
          >
            {{
              isOnRegisterRoute
                ? "Click here to Login"
                : "Click here to Register"
            }}
          </router-link>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import auth from "@/services/auth.service";
export default {
  name: "Login",
  data() {
    return {
      authForm: {
        email: "",
        password: "",
        confirmPassword: "",
      },
      valid: false,
      processingForm: false,
      requiredRule: [(v) => !!v || "This field is required"],
      message: "",
    };
  },
  computed: {
    isOnRegisterRoute() {
      return this.$route.name === "Signup";
    },
    emailRules() {
      return this.requiredRule.concat([
        (v) => /.+@.+/.test(v) || "Not a valid email",
      ]);
    },
    isPasswordEqual() {
      if (this.isOnRegisterRoute) {
        return this.authForm.password === this.authForm.confirmPassword;
      }
      return null;
    },
  },
  watch: {
    isPasswordEqual(val) {
      if (typeof val === "boolean") this.valid = val;
    },
  },
  methods: {
    async authenticateOrRegister() {
      if (this.valid) {
        this.processingForm = true;
        try {
          // @todo look into how to refactor this to just call a single function
          if (this.isOnRegisterRoute) {
            await auth.register(this.authForm);
          } else {
            await auth.login(this.authForm);
          }
        } catch (err) {
          if (err.response) {
            if (err.response.data.non_field_errors)
              this.message = err.response.data.non_field_errors[0];
            else {
              // for now we will display the first error of the first field
              // @todo attach field error to its field

              this.message = Object.values(err.response.data)[0][0];
            }
          } else {
            this.message = "Something went wrong";
            console.log(err);
          }
        }
        this.processingForm = false;
      }
    },
  },
};
</script>
