<template>
  <v-container fluid>
    <div class="d-flex justify-center align-center vh-100" v-if="loading || !fleet">
      <v-progress-circular v-if="loading" :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
      <div v-else class="d-flex flex-column h-25 justify-space-between">
        <template v-if="staff">
          <span class="black--text text--darken-4 font-weight-black text-center text-h2" :maxlength="maxLength">{{
            errorDetails.errorHeader }}</span>
          <v-icon x-large color="red" style="font-size: 160px">
            mdi-alert-octagon-outline
          </v-icon>
          <span class="red--text text--darken-4 font-weight-black text-center pb-3">Something bad happened :(</span>
          <span class="black--text text--darken-4 font-weight-thin text-body-2 text-left">Error: {{ errorDetails.message
          }}
            - Analytics.vue - fetchData() <br> Error Details: {{ errorDetails.errData.substr(0, 80) }} </span>
          <span class="black--text text--darken-4 font-weight-thin text-body-2 text-left pb-6">
            Response URL:
            <a :href="errorDetails.url" class="text--grey-darken-3 font-weight-light text-body-2 text-left"> {{
              errorDetails.url }}</a>
          </span>
        </template>
        <template v-else>
          <v-icon x-large color="red" style="font-size: 160px">
            mdi-alert-octagon-outline
          </v-icon>
          <span class="red--text text--darken-4 font-weight-black text-center pb-3">Something bad happened :(</span>
        </template>
        <v-btn to="/" dark elevation="0" color="red--text">Go back to fleet overview</v-btn>
      </div>
    </div>
    <template v-else>
      <v-row>
        <v-col cols="12" lg="6">
          <h1 class="d-flex font-weight-light display-1 my-4">
            {{ fleet.display_name }}
          </h1>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" lg="3">
          <v-menu v-model="datePickerMenu" :close-on-content-click="false" transition="scale-transition" offset-y
            min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="dateRangeText" label="Date Range" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="dateRange" range @change="onDatePick"></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="4">
          <v-card height="219" class="d-flex justify-center align-center flex-column">
            <v-card-title class="display-2">{{
              fleet.analytics.average.toLocaleString()
            }}</v-card-title>
            <v-card-text class="text-center text-uppercase font-weight-bold">Average Daily Impressions</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card height="219" class="d-flex justify-center align-center flex-column">
            <v-card-title class="display-2">{{
              fleet.analytics.total.toLocaleString()
            }}</v-card-title>
            <v-card-text class="text-center text-uppercase font-weight-bold font-weight--bold">Total Impressions</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card height="219" class="d-flex justify-center align-center flex-column pa-2">
            <v-img width="100%" max-height="240" :src="fleet.cover_photo" v-if="fleet.cover_photo">
            </v-img>

            <v-img width="100%" max-height="240" src="../assets/truck.png" v-if="!fleet.cover_photo">
            </v-img>
          </v-card>
        </v-col>
      </v-row>

      <!-- <v-row v-if="profile.is_superuser">
        <v-col cols="12" sm="6">
          <v-card height="240" class="d-flex justify-center align-center flex-column">
            <v-card-title class="display-2">{{ Math.floor(fleet.analytics.total * 0.73).toLocaleString() }}</v-card-title>
            <v-card-text class="text-center text-uppercase font-weight-bold">Opportunity to Contact</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card height="240" class="d-flex justify-center align-center flex-column">
            <v-card-title class="display-2">{{ Math.floor((fleet.analytics.total * 0.73) * 0.85).toLocaleString()
            }}</v-card-title>
            <v-card-text class="text-center text-uppercase font-weight-bold">Visibility Adjusted Contact</v-card-text>
          </v-card>
        </v-col>
      </v-row> -->

      <v-row>
        <v-col cols="12">
          <v-card height="400" class="d-flex">
            <AnalyticsHeatmap :start="options.start" :end="options.end" :id="options.id"
              :locations="fleet.analytics.locations" :fsa_data="fleet.analytics.fsa_data" :center="fleet.center" />
          </v-card>
        </v-col>
      </v-row>

      <!-- <v-row v-if="profile.is_superuser">
        <v-col cols="12">
          <v-card>
            <v-card-text class="text-center text-uppercase font-weight-bold">Dwell Time</v-card-text>
            <AnalyticsChartBar class="w-100" id="analytics-dwell" name="Dwell Times" :value="dwell_times" />
          </v-card>
        </v-col>
      </v-row> -->


      <v-row>
        <v-col cols="12" lg="6">
          <v-card>
            <v-card-text class="text-center text-uppercase font-weight-bold">Impressions by city</v-card-text>
            <AnalyticsChartDoughnut :value="fleet.analytics.city_impression_data" />
          </v-card>
        </v-col>
        <v-col cols="12" lg="6">
          <v-card>
            <v-card-text class="text-center text-uppercase font-weight-bold">Impressions by Region</v-card-text>
            <AnalyticsChartDoughnut :value="fleet.analytics.region_impression_data" />
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-text class="text-center text-uppercase font-weight-bold">Daily Impressions</v-card-text>
            <AnalyticsChartBar class="w-100" id="analytics-daily" name="Impressions"
              :value="fleet.analytics.daily_impression_data" />
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-card-text class="text-center text-uppercase font-weight-bold">Hourly Impressions</v-card-text>
            <AnalyticsChartBar class="w-100" id="analytics-hourly" name="Impressions"
              :value="fleet.analytics.hourly_impression_data" />
          </v-card>
        </v-col>
      </v-row>
      <v-row v-show="fleet.dimensions">
        <v-col cols="12">
          <v-card>
            <v-card-text class="text-center text-uppercase font-weight-bold">Truck Dimensions</v-card-text>
            <v-simple-table class="text-center">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">Total Square Footage</th>
                    <th class="text-center">Side Width</th>
                    <th class="text-center">Side Height</th>
                    <th class="text-center">Back Width</th>
                    <th class="text-center">Back Height</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ fleet.dimensions.sq_footage }} Sq Ft.</td>
                    <td>{{ fleet.dimensions.side_width }} In.</td>
                    <td>{{ fleet.dimensions.side_height }} In.</td>
                    <td>{{ fleet.dimensions.back_width }} In.</td>
                    <td>{{ fleet.dimensions.back_height }} In.</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-show="fleet.photos">
        <v-col cols="12">
          <v-card>
            <v-card-text class="text-center text-uppercase font-weight-bold">Truck Photos</v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="4" lg="3" v-for="(photo, i) in fleet.photos" :key="i" :src="photo">
                <v-img :src="photo" height="200"></v-img>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <v-snackbar v-model="snackbar" :right="true" :top="true" :min-width="300" color="red darken-4">
      <v-icon large>mdi-close-octagon-outline</v-icon>
      <span class="font-weight-medium ml-3">Couldn't load data</span>
    </v-snackbar>
  </v-container>
</template>

<script>
import AnalyticsChartDoughnut from "@/components/AnalyticsChartDoughnut";
import AnalyticsChartBar from "@/components/AnalyticsChartBar";
import AnalyticsHeatmap from "@/components/AnalyticsHeatmap";
import handleError from "@/views/ErrorHandler";

export default {
  name: "Analytics",
  components: {
    AnalyticsChartBar,
    AnalyticsChartDoughnut,
    AnalyticsHeatmap,
  },
  data() {
    return {
      dwell_times: {
        "0-2 Seconds": Math.floor(Math.random() * (30 - 20) + 20),
        "3-5 Seconds": Math.floor(Math.random() * (40 - 30) + 30),
        "5-30 Seconds": Math.floor(Math.random() * (20 - 10) + 10),
        "30-60 Seconds": Math.floor(Math.random() * (17 - 7) + 7),
        "1-2 Minutes": Math.floor(Math.random() * (18 - 8) + 8),
        "2-15 Minutes": Math.floor(Math.random() * (9 - 0) + 1),
        "> 15 Minutes": 1,
      },
      errorDetails: {
        message: null,
        errData: null,
        errorHeader: null,
        url: null
      },
      staff: false,
      maxLength: 10,
      datePickerMenu: false,
      dateRange: [null, null],
      fleet: null,
      loading: false,
      snackbar: false,
      options: {
        id: null,
        campaign: null,
        label: null,
        precision: null,
        start: new Date(
          Date.now() -
          24 * 3600 * 28000 -
          new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
        end: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        raw: false,
        scans: false,
        nomap: false,
      },
      VALID_QSTRING_KEYS: {
        id: String,
        campaign: String,
        label: String,
        precision: String,
        start: String,
        end: String,
        f: String,
        k: String,
        raw: Boolean,
        scans: Boolean,
        nomap: Boolean,
        nofsa: Boolean,
        locpie: Boolean,
        new: Boolean,
        distance: Boolean,
      },
    };
  },
  computed: {
    dateRangeText() {
      return `${this.options.start} ➜ ${this.options.end}`;
    },
    details() {
      const { display_name, photos, cover_photo, dimensions } = this.fleet;
      return { display_name, photos, cover_photo, dimensions };
    }
  },
  watch: {
    $route() {
      this.fetchData();
    },
  },
  methods: {
    async fetchData() {
      // close the datePicker before starting to fetch data
      this.datePickerMenu = false;
      this.loading = true;
      try {
        // user permissions
        this.profile = await this.$api.getProfile();
        this.staff = this.profile.is_staff;

        const qstring = this.generateQstring();
        this.fleet = await this.$api.getFleetAnalytics(qstring);
        this.$store.commit("setCurrentTruck", this.fleet);
        this.loading = false;
      } catch (er) {
        this.errorDetails = handleError(er);

        this.snackbar = true;
        this.loading = false;

      }
    },
    parseQstring(qstring) {
      var out = {};
      var strings = qstring.split("&");
      for (const string of strings) {
        var substrings = string.split("=");
        if (substrings[0] in this.VALID_QSTRING_KEYS) {
          if (
            substrings.length == 1 &&
            this.VALID_QSTRING_KEYS[substrings[0]] == Boolean
          ) {
            out[substrings[0]] = true;
          } else if (substrings.length == 2) {
            out[substrings[0]] = substrings[1];
          }
        }
      }
      return out;
    },
    generateQstring() {
      var out = "";
      for (const key of Object.keys(this.VALID_QSTRING_KEYS)) {
        if (key in this.options && this.options[key]) {
          if (key != "id") {
            out += "&";
          }
          if (this.VALID_QSTRING_KEYS[key] == Boolean) {
            out += `${key}`;
          } else {
            out += `${key}=${this.options[key]}`;
          }
        }
      }
      return out;
    },
    onDatePick() {
      this.options.start = this.dateRange[0];
      this.options.end = this.dateRange[1];
      this.$router.replace({ params: { qstring: this.generateQstring() } });
      this.fetchData();
    },
  },
  mounted() {
    Object.assign(this.options, this.parseQstring(this.$route.params.qstring));
    // this.options.id = this.$route.query.id;
    this.dateRange = [this.options.start, this.options.end];
    this.fetchData();
  },
};
</script>

<style>
#map {
  height: 100%;
  width: 100%;
}

.map-frame {
  width: 100%;
  height: 100%;
  position: relative;
}

.map-content {
  z-index: 10;
  position: absolute;
  top: 50px;
  left: 50px;
  width: 390px;
  background-color: black;
  color: #fff;
}
</style>
