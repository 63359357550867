<template>
  <apexchart
    type="bar"
    height="400"
    :options="chartOptions"
    :series="chartData"
  ></apexchart>
</template>
<script>
export default {
  name: "AnalyticsChartBar",
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      required: true,
      default: function _default() {},
    },
  },
  computed: {
    chartData() {
      return [
        {
          name: this.name,
          data: Object.values(this.value),
        },
      ];
    },
    chartOptions() {
      return {
        fill: {
          // colors: ["primary"],
        },
        chart: {
          id: this.id,
        },
        xaxis: {
          categories: Object.keys(this.value),
        },
        dataLabels: {
          enabled: false,
        },
      };
    },
  },
};
</script>
