/**
 * Custom Error handler for error formatting
 * @param {Error} error 
 * @returns errorDetails
 */
export default function handleError(error){
    
    var url = "";
    var errorHeader = "Oops!";
    var errData = "Refer to logs for further details.";
    const { request, response } = error;
    var status = response.status;
    const message = error.message;
    const config = response.config;
    
    if (status >= 300 && status < 500) {
        // The error request was made and the server responded with a status code
        // that falls out of the range of 2xx
            console.log("Something went wrong getting data:", response.status);
            console.log("Error response data: ",response.data);
            console.log("Error response headers: ",response.headers);
            url += config.baseURL + config.url;
            errData = JSON.stringify(response.data);
            errorHeader =  status + " - " + response.statusText;

            return {
            message,
            errData,
            errorHeader,
            url
            };
        
      } else if (request) {
         // The error request was made but no response was received
         // Includes 500 errors or errors less than 200 status code
            status = request.status;
            console.log("Something went wrong getting data:", message);
            console.log('Error Request Info: ', error.request);
           
            errorHeader =  request.status + " - " + request.statusText;
            url += request.responseURL;

            return {
                message,
                errData,
                errorHeader,
                url
            };
        
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Something went wrong getting data:", error.config);
        console.log('Error: ', message);
        url += request.responseURL;
        return {
            message,
            errData,
            errorHeader,
            url
        };
      }
}