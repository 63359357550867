<template>
  <div>
    <div class="d-flex grey lighten-2 mt-n3 mx-n3 mb-15" style="height: 78px">
      <h1 class="ma-auto">Fleet Health</h1>
    </div>
    <v-row class="mx-6">
      <v-col cols="12">
        <h2
          v-if="response.meta"
          style="font-weight: 300; color: #aaaaaa; font-size: 2rem"
        >
          {{ response.meta.healthy_trucks }} out of
          {{ response.meta.total_active_campaign_trucks }} trucks are operating
          optimally
        </h2>
        <v-card class="my-7 py-8">
          <v-row justify="space-around">
            <v-col cols="2" class="text-center">
              <h4 class="mb-3">OVERALL</h4>
              <v-progress-circular
                :size="100"
                :width="1"
                :value="0"
                :color="overallHealth | filterColor"
                class="text-h2 font-weight-black"
              >
                {{ overallHealth | grade }}
              </v-progress-circular>
            </v-col>
            <v-col cols="2">
              <h4 class="mb-3">DATA HEALTH</h4>
              <v-progress-circular
                :size="100"
                :width="15"
                :value="dataHealth"
                :color="dataHealth | filterColor"
              >
                {{ dataHealth | toFixed }}
              </v-progress-circular>
            </v-col>
            <v-col cols="2">
              <h4 class="mb-3">CONFIG HEALTH</h4>
              <v-progress-circular
                :size="100"
                :width="15"
                :value="configHealth"
                :color="configHealth | filterColor"
              >
                {{ configHealth | toFixed }}
              </v-progress-circular>
            </v-col>
            <v-col cols="2">
              <h4 class="mb-3">STATISTICS</h4>
              <v-progress-circular
                :size="100"
                :width="1"
                :value="0"
                color="teal"
                id="progress-stat"
              >
                <span class="font-weight-medium black--text"
                  >{{ issuesCount }} issues</span
                >
                <span class="font-weight-medium black--text"
                  >{{ warningCount }} warnings</span
                >
              </v-progress-circular>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col id="needs-attention" cols="12">
        <h2
          class="mb-5"
          style="font-weight: 300; color: #aaaaaa; font-size: 2rem"
        >
          Action Items
        </h2>
        <InsightsTable
          :campaign="needsAttentionTrucks"
          @truckClick="currentTruck = $event"
          :isSingleCampaign="false"
        />
      </v-col>
      <v-col id="campaigns" cols="12">
        <h2
          class="mb-5"
          style="font-weight: 300; color: #aaaaaa; font-size: 2rem"
        >
          Campaigns
        </h2>
        <InsightsTable
          v-for="c in campaigns"
          :campaign="c"
          :id="c.id"
          :key="c.meta.name"
          @truckClick="currentTruck = $event"
          @loaded="handleCampaignTableLoaded"
        />
      </v-col>
    </v-row>
    <v-dialog v-model="displayTruckDialog" width="500">
      <InsightsTruckCard v-if="currentTruck" :truck="currentTruck" />
    </v-dialog>
  </div>
</template>
<script>
export default {
  data: () => ({
    response: {},
    displayTruckDialog: false,
    currentTruck: null,
    optimalTrucksCount: 0,
    issuesCount: 0,
    warningCount: 0,
  }),
  components: {
    InsightsTable: () => import("@/components/InsightsTable"),
    InsightsTruckCard: () => import("@/components/InsightsTruckCard"),
  },
  computed: {
    needsAttentionTrucks() {
      let trucks = [];

      this.response.campaigns &&
        this.response.campaigns.forEach((campaign) => {
          trucks.push(
            ...campaign.trucks.filter((truck) => truck.warnings.length > 0)
          );
        });

      return {
        trucks,
        meta: {
          name: "Needs Attention",
        },
      };
    },
    campaigns() {
      const cmgns = [];
      if (this.response.campaigns) {
        for (let i = this.response.campaigns.length - 1; i >= 0; i--) {
          const campaign = this.response.campaigns[i];
          cmgns.push({
            id: `campaign-${
              campaign.meta.admin_url.split("campaign/")[1].split("/")[0]
            }`,
            name: campaign.meta.name,
            ...campaign,
          });
        }
      }

      return cmgns;
    },
    dataHealth() {
      if (this.response.meta) {
        const { data_grade } =
          this.response.meta;
        return (
          data_grade
        );
      } else return 0;
    },
    configHealth() {
      if (this.response.meta) {
        const { config_grade } =
          this.response.meta;
        return (
          config_grade
        );
      } else return 0;
    },
    overallHealth() {
      if (this.response.meta) {
        const { overall_grade } =
          this.response.meta;
        return (
          overall_grade
        );
      } else return 0;
    },
  },
  filters: {
    filterColor(val) {
      if (val >= 70) return "green lighten-1";
      else if (val >= 50) return "insightorange";
      else return "red lighten-1";
    },
    grade(score) {
      if (score >= 90) {
        return "A";
      } else if (score >= 80) {
        return "B";
      } else if (score >= 70) {
        return "C";
      } else if (score >= 60) {
        return "D";
      } else if (score >= 50) {
        return "E";
      } else {
        return "F";
      }
    },
  },
  watch: {
    currentTruck(val) {
      if (val) this.displayTruckDialog = true;
    },
    displayTruckDialog(val) {
      if (!val) this.currentTruck = null;
    },
  },
  methods: {
    handleCampaignTableLoaded({
      normalTrucks,
      errorMessages,
      warningMessages,
    }) {
      this.optimalTrucksCount += normalTrucks;
      this.issuesCount += errorMessages;
      this.warningCount += warningMessages;
    },
  },
  async mounted() {
    this.response = await this.$api.getInsights();
    this.$store.commit("setCampaigns", this.campaigns);
  },
};
</script>

<style>
#progress-stat .v-progress-circular__info {
  flex-direction: column;
}
</style>
