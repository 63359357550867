import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentTruck: {},
    campaigns: []
  },
  mutations: {
    setCurrentTruck: (state, pld) => ( state.currentTruck = pld ),
    setCampaigns: (state, pld) => (state.campaigns = pld)
  },
  actions: {
  },
  getters: {
  },
  modules: {
  }
})
