<template>
  <v-container fluid>
    <div
      class="d-flex justify-center align-center vh-100"
      v-if="loading || !sessions"
    >
      <v-progress-circular
        v-if="loading"
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <div v-else class="d-flex flex-column h-25 justify-space-between">
        <v-icon x-large color="red" style="font-size: 160px">
          mdi-alert-octagon-outline
        </v-icon>
        <span class="red--text text--darken-4 font-weight-black text-center pb-3"
          >Something bad happened :(</span
        >
        <v-btn to="/" dark elevation="0" color="red--text"
          >Go back to fleet overview</v-btn
        >
      </div>
    </div>
    <template v-else>
      <v-row no-gutters class="mt-8">
        <v-col>
          <v-data-table
            dense
            :headers="truckHeaders"
            :items="trucks"
            :items-per-page="10"
            :search="search"
          >
            <template #item.truck="{ item }">
              <v-btn @click="loadTruck(item.truck)"> {{ item.truck }}</v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12" sm="12">
          <v-card class="mx-auto" style="height: 400px" color="grey lighten-4">
            <v-progress-linear
              v-model="loaded"
              color="primary"
              :v-if="loaded < 100"
            ></v-progress-linear>

            <l-map ref="map" id="map" :zoom="zoom" :center="latLng">
              <l-tile-layer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                :attribution="$root.mapTileAttribution"
              >
              </l-tile-layer>
            </l-map>
          </v-card>
        </v-col>
      </v-row>

      <v-row no-gutters class="mt-8">
        <v-col>
          <v-data-table
            dense
            :headers="sessionHeaders"
            :items="sessions"
            :items-per-page="20"
            :search="search"
          >
            <!-- <template v-slot:item.kml="{ item }">
              {{ item.kml }}
            </template> -->
          </v-data-table>
        </v-col>
      </v-row>
    </template>
    <v-snackbar
      v-model="snackbar"
      :right="true"
      :top="true"
      :min-width="300"
      color="red darken-4"
    >
      <v-icon large>mdi-close-octagon-outline</v-icon>
      <span class="font-weight-medium ml-3">Couldn't load data</span>
    </v-snackbar>
  </v-container>
</template>

<script>
import omnivore from "leaflet-omnivore";
export default {
  name: "Profile",
  data() {
    return {
      search: "",
      trucks: [],
      sessions: [],
      truckHeaders: [
        { text: "Truck ID", value: "truck" },
        { text: "Sessions", value: "dcount" },
      ],
      sessionHeaders: [
        { text: "Start Time", value: "start_time" },
        { text: "End Time", value: "end_time" },
        { text: "Average Speed", value: "avg_speed" },
        { text: "Distance", value: "distance" },
      ],
      latLng: [42.1164, -101.2996],
      zoom: 4,
      loading: false,
      snackbar: false,
      loaded: 0,
      currentCount: 0,
      device: "",
    };
  },
  methods: {
    clearMap() {
      // const map = this.$refs.map.mapObject;
    },

    loadTruck(truckId) {
      this.$api.getSessionData(truckId).then((session) => {
        this.sessions = session;
        for (const k of Object.entries(this.sessions)) {
          this.loadKML(k[1].kml);
        }
      });
    },
    loadKML(url) {
      var self = this;
      const map = this.$refs.map.mapObject;
      omnivore
        .kml(url)
        .on("ready", function () {
          self.currentCount++;
          self.loaded = (
            (self.currentCount / self.sessions.length) *
            100
          ).toFixed(0);
        })
        .addTo(map);
    },
  },
  mounted() {
    this.$api.getSessions().then((trucks) => {
      this.trucks = trucks;
    });
  },
};
</script>

<style>
#map {
  height: 100%;
  width: 100%;
  background: #000;
}
.map-frame {
  width: 100%;
  height: 100%;
  position: relative;
}

.map-content {
  z-index: 10;
  position: absolute;
  top: 50px;
  left: 50px;
  width: 390px;
  background-color: black;
  color: #fff;
}
</style>
