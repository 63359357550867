import axios from 'axios';
import auth from '@/services/auth.service'

const analytics = axios.create({
  baseURL: process.env.VUE_APP_ANALYTICS_API_URL, // you should set this in .env.local file, read more https://cli.vuejs.org/guide/mode-and-env.html
})

const c2 = axios.create({
  baseURL: process.env.VUE_APP_C2_API_URL, // you should set this in .env.local file, read more https://cli.vuejs.org/guide/mode-and-env.html
})

// Request interceptor
analytics.interceptors.request.use(function (config) {
  // Do something before request is sent

  /* 
    We will be setting tokens here, checking for expried tokens and refereshing token
  */
  const token = auth.getAuthTokenFromStorage()
  if (token)
    config.headers.Authorization = `JWT ${token}`

  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Response interceptor
analytics.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response.data;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error

  /* 
    Send user to login page when unauthenticated error comes from the api
  */
  if (error.response && error.response.status === 401) {
    // if we replace this with vue router, we should be doing a clear timeout for refresh token here
    auth.removeAuthTokenFromStorage()
    return window.location.replace('/auth');
  }
  return Promise.reject(error);
});

function login(email, password) {
  return analytics.post('/auth/login/', { email, password })
}

function register(username, email, password1, password2) {
  return analytics.post('/auth/registration/', { username, email, password1, password2 })
}

function refreshToken(token) {
  return analytics.post('/token/refresh', { token })
}

function getFleets() {
  return analytics.get("/api/fleet/")
}

function getFleetAnalytics(qstring) {
  return analytics.get(`/api/analytics/${qstring}`)
}

function getFsaMap(qstring) {
  return analytics.get(`/api/fsamap/${qstring}`)
}

function getReport() {
  return analytics.get("/api/report")
}

function getProfile() {
  return analytics.get("/api/me")
}

function getSessionData(fleetId) {
  return analytics.get(`/api/session/${fleetId}`)
}

function updateDeviceOvernightAddress(fleetId, deviceId, environment, { latitude, longitude }) {
  return analytics.put(`/api/trucks/${fleetId}/`, {
    device: deviceId,
    environment,
    overnight_address_latitude: latitude,
    overnight_address_longitude: longitude
  })
}

function getInsights() {
  return analytics.get("api/insights/")
}

function getMACOrSSID(qtype, qstring) {
  return analytics.get(`/api/search/${qtype}/${qstring}`)
}

function getSessions(deviceId, startDate, endDate, minSpeed) {
  return analytics.get(`/api/findsessions/device_id=${deviceId}&start=${startDate}&end=${endDate}&interval=10&min_speed=${minSpeed}`)
}

function getLocations(deviceId, startDate, endDate, minSpeed) {
  return analytics.get(`/api/sessionmap/device_id=${deviceId}&start=${startDate}&end=${endDate}&interval=10&min_speed=${minSpeed}`)
}

// Request interceptor
c2.interceptors.request.use(function (config) {
  // Do something before request is sent

  /* 
    We will be setting tokens here, checking for expried tokens and refereshing token
  */
  const token = auth.getAuthTokenFromStorage()
  if (token)
    config.headers.Authorization = `JWT ${token}`

  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Response interceptor
c2.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response.data;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error

  /* 
    Send user to login page when unauthenticated error comes from the api
  */
  if (error.response && error.response.status === 401) {
    // if we replace this with vue router, we should be doing a clear timeout for refresh token here
    auth.removeAuthTokenFromStorage()
    return window.location.replace('/auth');
  }
  return Promise.reject(error);
});

function sendUpgradeCommand(deviceId) {
  return c2.post(`/api/upgrade`, {
    device_id: deviceId,
  })
}
function sendIdentifyCommand(deviceId) {
  return c2.post(`/api/identify`, {
    device_id: deviceId,
  })
}
function sendRebootCommand(deviceId) {
  return c2.post(`/api/reboot`, {
    device_id: deviceId,
  })
}

export default {
  login,
  register,
  getFleets,
  getProfile,
  getReport,
  getFleetAnalytics,
  getFsaMap,
  getSessionData,
  refreshToken,
  getInsights,
  updateDeviceOvernightAddress,
  sendRebootCommand,
  sendIdentifyCommand,
  sendUpgradeCommand,
  getMACOrSSID,
  getSessions,
  getLocations,
}
