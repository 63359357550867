<template>
  <div>
    <v-sheet class="pa-md-4 mx-lg-auto" flat>
      <v-row>
        <v-col cols="6">
          <h1 class="d-flex font-weight-light display-1">
            Events for {{ viewType }} "{{ viewSSID }}"
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" md="12">
          <v-card class="mx-auto" style="height: 640px" color="grey lighten-4">
            <l-map
              id="map"
              :zoom="zoom"
              :center="latLng"
              style="height: 100%"
              ref="map"
            >
              <l-tile-layer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                :attribution="$root.mapTileAttribution"
              >
              </l-tile-layer>
              <l-marker
                :lat-lng="[ev.lat, ev.lon]"
                :icon="getIcon(ev.type)"
                v-for="ev in validateLatLon"
                :key="ev.timestamp"
              >
                <l-popup>
                  <v-card class="mx-auto" width="640" flat>
                    <v-list-item three-line>
                      <v-list-item-content>
                        <div class="text-overline mb-4">
                          {{ ev.type }}
                        </div>
                        <v-list-item-title class="text-h5 mb-1" v-if="ev.ssid">
                          {{ ev.ssid }}
                        </v-list-item-title>
                        <v-list-item-title class="text-h5 mb-1" v-if="!ev.ssid">
                          N/A
                        </v-list-item-title>

                        <v-list-item-subtitle
                          >{{ ev.city }}, {{ ev.region }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-card-text>
                      {{ ev.timestamp | moment("from") }}
                      <br />
                      <strong>MAC</strong> {{ ev.mac_addr }}
                      <br />
                      <strong>Encryption</strong>
                      {{ getEncText(ev.encryption) }}
                      <br />
                      <strong>Vendor</strong> {{ ev.vendor }}
                      <br />
                      <strong>Channel</strong> {{ ev.channel }}
                      <br />
                      <strong>RSSI</strong> {{ ev.rssi }} ({{
                        rssi2percent(ev.rssi)
                      }}
                      %)
                    </v-card-text>

                    <v-card-actions>
                      <v-btn x-small color="blue" dark>
                        Truck {{ ev.truck_id }}
                      </v-btn>
                      <v-btn x-small color="blue" dark>
                        Device {{ ev.device_id }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </l-popup>
              </l-marker>
            </l-map>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h1 class="display-1">Scans</h1>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-data-table
            dense
            :headers="headers"
            :items="events"
            :items-per-page="25"
            :search="search"
            height="900px"
          >
            <template v-slot:item.type="{ item }">
              <v-chip :color="getTypeColor(item.type)" dark small>
                <v-icon>{{ getTypeIcon(item.type) }}</v-icon>
              </v-chip>
            </template>

            <template v-slot:item.encryption="{ item }">
              <v-chip :color="getEncColor(item.encryption)" dark small>
                {{ getEncText(item.encryption) }}
              </v-chip>
            </template>

            <template v-slot:item.mac_addr="{ item }">
              <router-link :to="{ path: `/mac/${item.mac_addr}` }">
                {{ item.mac_addr }}
              </router-link>
            </template>

            <template v-slot:item.truck_id="{ item }">
              <router-link :to="{ path: `/analytics/id=${item.truck_id}` }">
                {{ item.truck_id }}
              </router-link>
            </template>

            <template v-slot:item.device_id="{ item }">
              <router-link :to="{ path: `/explore/${item.device_id}` }">
                {{ item.device_id }}
              </router-link>
            </template>

            <template v-slot:item.ssid="{ item }">
              <span v-if="item.ssid">
                <router-link :to="{ path: `/ssid/${item.ssid}` }">
                  {{ item.ssid }}
                </router-link>
              </span>
            </template>

            <template v-slot:item.timestamp="{ item }">
              <span :title="item.timestamp">
                {{ item.timestamp | moment("from") }}
              </span>
            </template>

            <template v-slot:item.rssi="{ item }">
              {{ rssi2percent(item.rssi) }}%
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>
<script>
import L from "leaflet";
export default {
  name: "ExploreDevice",
  data: () => ({
    ssid: "",
    viewType: "",
    viewSSID: "",
    mac: "",
    search: "",
    events: [],
    latLng: [42, -100],
    zoom: 4,
    headers: [
      { text: "Type", value: "type" },
      { text: "Timestamp", value: "timestamp" },
      { text: "MAC", value: "mac_addr" },
      { text: "SSID", value: "ssid" },
      { text: "Vendor", value: "vendor" },
      { text: "RSSI", value: "rssi" },
      { text: "Channel", value: "channel" },
      { text: "Encryption", value: "encryption" },
      { text: "City", value: "city" },
      { text: "Region", value: "region" },
      { text: "Truck", value: "truck_id" },
      { text: "Device", value: "device_id" },
    ],
  }),
  computed: {
    validateLatLon() {
      return this.events.filter((event) => event.lat && event.lon);
    },
  },
  methods: {
    getIcon(type) {
      if (type == "ble") {
        return L.divIcon({
          className: "my-custom-pin",
          html: `<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="16" height="16" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="blue" d="M14.88 16.29L13 18.17v-3.76m0-8.58l1.88 1.88L13 9.58m4.71-1.87L12 2h-1v7.58L6.41 5L5 6.41L10.59 12L5 17.58L6.41 19L11 14.41V22h1l5.71-5.71l-4.3-4.29l4.3-4.29Z"/></svg>`,
        });
      }

      if (type == "station") {
        return L.divIcon({
          className: "my-custom-pin",
          html: `<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="16" height="16" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="red" d="M4.93 4.93A9.969 9.969 0 0 0 2 12c0 2.76 1.12 5.26 2.93 7.07l1.41-1.41A7.938 7.938 0 0 1 4 12c0-2.21.89-4.22 2.34-5.66L4.93 4.93m14.14 0l-1.41 1.41A7.955 7.955 0 0 1 20 12c0 2.22-.89 4.22-2.34 5.66l1.41 1.41A9.969 9.969 0 0 0 22 12c0-2.76-1.12-5.26-2.93-7.07M7.76 7.76A5.98 5.98 0 0 0 6 12c0 1.65.67 3.15 1.76 4.24l1.41-1.41A3.99 3.99 0 0 1 8 12c0-1.11.45-2.11 1.17-2.83L7.76 7.76m8.48 0l-1.41 1.41A3.99 3.99 0 0 1 16 12c0 1.11-.45 2.11-1.17 2.83l1.41 1.41A5.98 5.98 0 0 0 18 12c0-1.65-.67-3.15-1.76-4.24M12 10a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2Z"/></svg>`,
        });
      }
      if (type == "probe") {
        return L.divIcon({
          className: "my-custom-pin",
          html: `<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="16" height="16" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="darkgreen" d="M20.07 4.93A9.969 9.969 0 0 1 23 12c0 2.76-1.12 5.26-2.93 7.07l-1.41-1.41A7.938 7.938 0 0 0 21 12c0-2.21-.89-4.22-2.34-5.66l1.41-1.41m-2.83 2.83A5.98 5.98 0 0 1 19 12c0 1.65-.67 3.15-1.76 4.24l-1.41-1.41A3.99 3.99 0 0 0 17 12c0-1.11-.45-2.11-1.17-2.83l1.41-1.41M13 10a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2m-1.5-9A2.5 2.5 0 0 1 14 3.5V8h-2V4H3v15h9v-3h2v4.5a2.5 2.5 0 0 1-2.5 2.5h-8A2.5 2.5 0 0 1 1 20.5v-17A2.5 2.5 0 0 1 3.5 1h8Z"/></svg>`,
        });
      }
      if (type == "client") {
        return L.divIcon({
          className: "my-custom-pin",
          html: `<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="16" height="16" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="purple" d="m20.2 5.9l.8-.8C19.6 3.7 17.8 3 16 3c-1.8 0-3.6.7-5 2.1l.8.8C13 4.8 14.5 4.2 16 4.2s3 .6 4.2 1.7m-.9.8c-.9-.9-2.1-1.4-3.3-1.4c-1.2 0-2.4.5-3.3 1.4l.8.8c.7-.7 1.6-1 2.5-1c.9 0 1.8.3 2.5 1l.8-.8M19 13h-2V9h-2v4H5a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2M8 18H6v-2h2v2m3.5 0h-2v-2h2v2m3.5 0h-2v-2h2v2Z"/></svg>`,
        });
      }
    },
    rssi2percent(dBm) {
      if (dBm <= -100) {
        return 0;
      } else if (dBm >= -50) {
        return 100;
      }
      return 2 * (dBm + 100);
    },
    getEncColor(enc) {
      if (enc == "WPA2") {
        return "red";
      }
      if (enc == "OPEN") {
        return "green";
      }
      return "blue";
    },
    getEncText(enc) {
      if (enc == "WPA2") {
        return "WPA2";
      }
      if (enc == "OPEN") {
        return "OPEN";
      }
      return "N/A";
    },
    getTypeIcon(stype) {
      if (stype == "client") {
        return "mdi-router-wireless";
      }
      if (stype == "station") {
        return "mdi-access-point";
      }
      if (stype == "probe") {
        return "mdi-cellphone-wireless";
      }
      if (stype == "ble") {
        return "mdi-bluetooth";
      }
    },
    getTypeColor(stype) {
      if (stype == "client") {
        return "yellow";
      }
      if (stype == "station") {
        return "orange";
      }
      if (stype == "probe") {
        return "green";
      }
      if (stype == "ble") {
        return "blue";
      }
    },
  },
  async mounted() {
    var mac = this.$route.params.mac;
    var ssid = this.$route.params.ssid;
    var qtype = mac ? "mac" : "ssid";
    var qstring = mac ? mac : ssid;
    this.viewType = mac ? "MAC" : "SSID";
    this.viewSSID = mac ? mac : ssid;
    this.$api.getMACOrSSID(qtype, qstring).then((events) => {
      this.events = events;
      this.loaded = true;

      var locations = events.filter((event) => event.lat && event.lon);
      var bounds = new L.LatLngBounds(locations);
      this.$refs.map.mapObject.fitBounds(bounds);
      // this.$refs.map.mapObject.setZoom(8);
    });
  },
};
</script>

<style lang="scss">
.vue2leaflet-map {
  z-index: 1;
}
</style>
