<template>
  <l-map id="map" ref="map" :center="this.center" @ready="doSomethingOnReady()">
    <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" :attribution="$root.mapTileAttribution">
    </l-tile-layer>
  </l-map>
</template>

<script>
import "@/components/leaflet-heat.js";

/* global L */
export default {
  name: "AnalyticsHeatmap",
  mounted() {
    // console.log(this.id);
  },
  props: {
    id: { type: String, required: true },
    start: { type: String, required: true },
    end: { type: String, required: true },
    locations: { type: Array, required: true },
    fsa_data: { type: Array, required: true },
    center: { type: Array, required: true},
  },
  methods: {
    doSomethingOnReady() {
      const heatRadius = this.locations.map((location) => [
        location[0],
        location[1],
        2,
      ]);
      L.heatLayer(heatRadius, {
        radius: 2,
        maxZoom: 10,
        blur: 1,
        gradient: { 0: "lime", 0.65: "orange", 1: "red" },
      }).addTo(this.$refs.map.mapObject);
      for (const data of this.fsa_data) {
        L.polygon(data.bounds, { color: 'blue', stroke: true, opacity: 0.2 + 0.7 * data.weight, fillOpacity: 0.1 + data.weight * 0.7, weight: 2 }).addTo(this.$refs.map.mapObject);
      }

      var map_center = this.$refs.map.mapObject.getCenter();
      var bounds = this.$refs.map.mapObject.getBounds();
      // This centers pretty well based on latest lat/lon
      this.$refs.map.mapObject.setView(map_center);
      // I'm not suer if this is actually what it's supposed to
      this.$refs.map.mapObject.fitBounds(bounds);
      // This works, but not with fitBoundsZoom() ....
      this.$refs.map.mapObject.setZoom(8);
    },
  },
};
</script>
