<template>
  <apexchart
    type="pie"
    height="300"
    :options="chartOptions"
    :series="chartData"
  ></apexchart>
</template>
<script>
export default {
  name: "AnalyticsChartDoughnut",
  props: {
    value: {
      type: Object,
      required: true,
      default: function _default() {},
    },
  },
  computed: {
    chartData() {
      return Object.values(this.value);
    },
    chartOptions() {
      return {
        labels: Object.keys(this.value),
        // colors: [
        //   "#90CAF9",
        //   "#64B5F6",
        //   "#42A5F5",
        //   "#2196F3",
        //   "#1E88E5",
        //   "#1976D2",
        //   "#1565C0",
        //   "#0D47A1",
        //   "#1565C0",
        //   "#0D47A1",
        // ],
        dataLabels: {
          enabled: false,
        },
      };
    },
  },
};
</script>
