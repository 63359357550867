<template>
  <div class="mt-4">
    <h1 class="d-flex font-weight-light display-1 my-4">Fleet Overview</h1>
    <DashboardMap :fleets="fleets" />
    <v-container fluid>
      <v-row>
        <v-col
          v-for="fleet in fleets"
          :key="fleet.id"
          lg="3"
          md="4"
          sm="6"
          :v-if="fleet.cover_photo"
        >
          <DashboardFleetCard :fleet="fleet" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Dashboard",
  components: {
    DashboardMap: () => import("@/components/DashboardMap"),
    DashboardFleetCard: () => import("@/components/DashboardFleetCard"),
  },
  data() {
    return {
      fleets: this.$root.truckList,
    };
  },
};
</script>
