<template>
  <div class="h-100 d-flex flex-column justify-center align-center">
    <h1 class="display-4">404</h1>
    <h3>The page you are looking for cannot be found</h3>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
