<template>
  <v-container fluid>
    <div class="d-flex justify-center align-center vh-100" v-if="loading || !data">
      <v-progress-circular v-if="loading" :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
      <div v-else class="d-flex flex-column h-25 justify-space-between">
        <template v-if="staff">
          <span class="black--text text--darken-4 font-weight-black text-center text-h2" :maxlength="maxLength">{{errorDetails.errorHeader}}</span>
          <v-icon x-large color="red" style="font-size: 160px">
            mdi-alert-octagon-outline
          </v-icon>
          <span class="red--text text--darken-4 font-weight-black text-center pb-3">Something bad happened :(</span>
          <span class="black--text text--darken-4 font-weight-thin text-body-2 text-left">Error: {{errorDetails.message}} - FsaMap.vue - fetchData() <br> Error Details: {{ errorDetails.errData.substr(0,80)}} </span>
          <span class="black--text text--darken-4 font-weight-thin text-body-2 text-left pb-6">
            Response URL:
            <a :href="errorDetails.url" class="text--grey-darken-3 font-weight-light text-body-2 text-left"> {{ errorDetails.url}}</a>
          </span>
        </template>
        <template v-else>
          <v-icon x-large color="red" style="font-size: 160px">
              mdi-alert-octagon-outline
          </v-icon>
          <span class="red--text text--darken-4 font-weight-black text-center pb-3">Something bad happened :(</span>
        </template>
        <v-btn to="/" dark elevation="0" color="red--text">Go back to fleet overview</v-btn>
      </div>
    </div>
    <template v-else>
      <v-row>
        <v-col cols="12">
          <v-card height="400" class="d-flex">
            <AnalyticsHeatmap :start="options.start" :end="options.end" :id="options.id" :locations="fetchedData.locations" :fsa_data="fetchedData.fsa_data" :center="fetchedData.center"/>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <v-snackbar v-model="snackbar" :right="true" :top="true" :min-width="300" color="red darken-4">
      <v-icon large>mdi-close-octagon-outline</v-icon>
      <span class="font-weight-medium ml-3">Couldn't load data</span>
    </v-snackbar>
  </v-container>
</template>

<script>
import AnalyticsHeatmap from "@/components/AnalyticsHeatmap";
import handleError from "@/views/ErrorHandler";
export default {
  name: "FsaMap",
  components: {
    AnalyticsHeatmap,
  },
  data() {
    return {
      fetchedData: null,
      loading: false,
      snackbar: false,
      options: {
        fsa: null,
        start: null,
        end: null
      },
      VALID_QSTRING_KEYS: {
        fsa: String,
        start: String,
        end: String
      },
      errorDetails: {
      message: null,
      errData: null,
      errorHeader: null,
      url: null
      },
      staff: false,
      maxLength: 10,
    };
  },
  computed: {
  },
  watch: {
    $route() {
      this.fetchData();
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        // user permissions
        this.profile = await this.$api.getProfile();
        this.staff = this.profile.is_staff;

        const qstring = this.generateQstring();
        this.fetchedData = await this.$api.getFsaMap(qstring);
        this.loading = false;
      } catch (er) {
        this.errorDetails = handleError(er);
        this.snackbar = true;
        this.loading = false;
      }
    },
    parseQstring(qstring) {
      var out = {};
      var strings = qstring.split("&");
      for (const string of strings) {
        var substrings = string.split("=");
        if (substrings[0] in this.VALID_QSTRING_KEYS) {
          if (
            substrings.length == 1 &&
            this.VALID_QSTRING_KEYS[substrings[0]] == Boolean
          ) {
            out[substrings[0]] = true;
          } else if (substrings.length == 2) {
            out[substrings[0]] = substrings[1];
          }
        }
      }
      return out;
    },
    generateQstring() {
      var out = "";
      for (const key of Object.keys(this.VALID_QSTRING_KEYS)) {
        if (key in this.options && this.options[key]) {
          if (key != "id") {
            out += "&";
          }
          if (this.VALID_QSTRING_KEYS[key] == Boolean) {
            out += `${key}`;
          } else {
            out += `${key}=${this.options[key]}`;
          }
        }
      }
      return out;
    }
  },
  mounted() {
    Object.assign(this.options, this.parseQstring(this.$route.params.qstring));
    this.fetchData();
  },
};
</script>

<style>
#map {
  height: 100%;
  width: 100%;
}

.map-frame {
  width: 100%;
  height: 100%;
  position: relative;
}

.map-content {
  z-index: 10;
  position: absolute;
  top: 50px;
  left: 50px;
  width: 390px;
  background-color: black;
  color: #fff;
}
</style>
